import React, { useEffect } from 'react';
import images from '../../assets/images';
import DurationButton from './DurationButton';
import PlayButton from './PlayButton';
import { connect, useDispatch, useSelector } from 'react-redux';
import { GAME_TYPES } from '../../constants/names';
import MenuTimer from './MenuTimer';
import { getSecondTillDraw } from '../../redux/actions/dataActions';
import { getKenoType } from '../../helpers/game';

const { NUMBERS } = GAME_TYPES;

const MenuScene = ({ gameVersions }) => {
  const dispatch = useDispatch();
  const time = useSelector((state) => state.secTillDrawReducer);
  const gameType = useSelector((state) => state.gameType);
  const kenoType = useSelector((state) => state.kenoType);

  useEffect(() => {
    dispatch(getSecondTillDraw(getKenoType()));
  }, [kenoType]);

  return (
    <>
      <main className="game-grid game-grid--welcome">
        <div className="welcome">
          <div className="welcome__left">
            <img
              src={gameType === NUMBERS ? images.numbersLogo : images.turboKenoLogo}
              alt="wrapper keno"
              width="733"
              height="370"
              className="welcome__keno-img"
            />
          </div>
          <div className="welcome__right">
            {time ? <MenuTimer time={time} /> : <div className="welcome__time">00 : 00</div>}
            {gameVersions.length > 1 && <div className="welcome__row welcome__row--inputs">
              {gameVersions.map((version) => {
                return <DurationButton key={version} duration={+version} />;
              })}
            </div>}
            <PlayButton />
          </div>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = ({ gameVersionsReducer }) => ({
  gameVersions: gameVersionsReducer,
});

export default connect(mapStateToProps)(MenuScene);
