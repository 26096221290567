import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  addBetButton,
  addGroupBetButton,
  deleteBetButton,
  deleteGroupBetButton,
} from '../../redux/actions/betBtnActions';
import { setError } from '../../redux/actions/errorActions';
import {
  BALL_COUNTS,
  digitsWithoutBottomBtn,
  digitsWithoutGroupBtn,
  digitsWithoutRightBtn,
} from '../../constants/game';
import { setIsBetCanceled } from '../../redux/actions/optionActions';
import { useSoundConstext } from '../../Context/SoundContext';

const BetButton = ({
  digit,
  color,
  addBetButton,
  addGroupBetButton,
  deleteGroupBetButton,
  selectedBetBtns,
  deleteBetButton,
  setHoverBtns,
  hoverBtns,
  setError,
}) => {
  const dispatch = useDispatch();
  const { isBetCanceled } = useSelector((state) => state.options);
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);
  const useSoundData = useSoundConstext();

  const isActive = selectedBetBtns.includes(digit);
  const groupDigits = [digit, digit + 1, digit + 10, digit + 11];
  const rightLeftBtns = [digit, digit + 1];
  const upDownBtns = [digit, digit + 10];

  const handleClick = (digit) => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));

    if (isActive) {
      deleteBetButton(digit);
    } else {
      selectedBetBtns.length < BALL_COUNTS.USER_TICKET && addBetButton(digit);
      if (selectedBetBtns.length == BALL_COUNTS.USER_TICKET) {
        setError('The ticket must include up to 10 numbers');
      }
    }
  };

  const handleGroupClick = (props) => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    const intersection = props.filter((element) => !selectedBetBtns.includes(element));
    const intLen = intersection.length;
    const remainingBalls = selectedBetBtns.filter((element) => !props.includes(element));
    if (intLen > 0 && selectedBetBtns.length + intLen <= 10) {
      addGroupBetButton(intersection);
    } else {
      intLen === 0 && deleteGroupBetButton(remainingBalls);
      if (selectedBetBtns.length + intLen > 10) {
        setError('The ticket must include up to 10 numbers');
      }
    }
  };

  return (
    <div className="bet-number-container">
      <button
        className={`bet-number-button ${color} ${isActive ? 'active' : ''} ${
          hoverBtns.includes(digit) ? 'group-btns_hover' : ''
        }`}>
        <span className="bet-number-button__span" onClick={() => handleClick(digit)}>
          {digit}
        </span>
      </button>
      {!(digit in digitsWithoutGroupBtn) && (
        <button
          className="bet-number-select-group"
          onMouseOver={() => setHoverBtns(groupDigits)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(groupDigits)}
        />
      )}
      {!(digit in digitsWithoutRightBtn) && (
        <button
          onMouseOver={() => setHoverBtns(rightLeftBtns)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(rightLeftBtns)}
          className="right-buttons"></button>
      )}
      {!(digit in digitsWithoutBottomBtn) && (
        <button
          onMouseOver={() => setHoverBtns(upDownBtns)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(upDownBtns)}
          className="bottom-buttons"></button>
      )}
    </div>
  );
};

const mapStateToProps = ({ selectedBetBtns }) => ({
  selectedBetBtns,
});

const mapDispatchToProps = {
  addBetButton,
  deleteBetButton,
  addGroupBetButton,
  deleteGroupBetButton,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(BetButton);
