import React from 'react';
import { connect } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import { closeErrorsPopup } from '../../redux/actions/errorActions';

const ErrorsPopup = ({ errorsPopup, closeErrorsPopup }) => {
  const languageData = useLanguageData();

  // console.log(errorsPopup, 'errorsPopup');
  // console.log(languageData, 'languageData');
  // console.log(languageData[errorsPopup], 'languageData[errorsPopup]');

  return (
    <div className={`${errorsPopup ? 'errorPopup active' : 'errorPopup'}`}>
      <div className="errorPopup__container">
        <div style={{ maxHeight: '10px', maxWidth: '10px' }}>
          <button onClick={() => closeErrorsPopup()} className="popup__close">
            Close popup
          </button>
        </div>
        <div className="errorPopup__title">{languageData[errorsPopup]}</div>
      </div>
      <div className="popup__wrapper" />
    </div>
  );
};

const mapStateToProps = ({ errorsPopup }) => ({
  errorsPopup,
});

const mapDispatchToProps = {
  closeErrorsPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorsPopup);
