import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { plusAmount } from '../../../redux/actions/amountActions';
import { setIsBetCanceled } from '../../../redux/actions/optionActions';
import { useSoundConstext } from '../../../Context/SoundContext';

const AmountButton = ({ amount, betAmount, plusAmount, betAmountOption }) => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const { isBetCanceled } = useSelector((state) => state.options);
  const isSoundActive = useSelector((state) => state.toggleSoundReducer);

  const clickHandler = () => {
    isSoundActive && useSoundData.addReduceAmount();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    plusAmount([betAmount, betAmountOption.maxBet]);
  };

  return (
    <li className="input-controls__item">
      <button onClick={clickHandler} className="input-control__button">
        +{amount}
      </button>
    </li>
  );
};

const mapStateToProps = ({ betAmountOptionReducer }) => ({
  betAmountOption: betAmountOptionReducer,
});

const mapDispatchToProps = {
  plusAmount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AmountButton);
