import React from 'react';
import { connect, useSelector } from 'react-redux';
import Ticket from './Ticket/Ticket';
import DynamicBetTicket from './DynamicBetTicket/DynamicBetTicket';
import { useLanguageData } from '../../../../Context/LanguageContext';

const CurrentGame = ({
  ticketReducer,
  ballFrequency,
  isRaffleActive,
  raffleTickets,
  pulledDigits,
  currentBall,
}) => {
  const languageData = useLanguageData();
  const selectedBetBtns = useSelector((state) => state.selectedBetBtns);
  const isTiketLineActive = isRaffleActive && !!raffleTickets.length && (!!selectedBetBtns.length || !!ticketReducer.length);
  return (
    <li className="game-grid__info-container game-grid__info-container--first active current-game current">
      <ul className="current-tickets">
        {isRaffleActive &&
          raffleTickets.map((ticket, index) => (
            <Ticket
              key={index}
              ticket={ticket}
              number={index + 1}
              ballFrequency={ballFrequency}
              pulledDigits={pulledDigits}
            />
          ))}
        {isTiketLineActive && <div className="tiketsLineWrapper">
            <div className='ticketsLine' />
            <div className='ticketsLineText'>{languageData['Next draw’s tickets']}</div>
        </div>}
        {(selectedBetBtns.length && <DynamicBetTicket ballFrequency={ballFrequency} />) || ''}
        {ticketReducer.map((ticket, index) => (
          <Ticket key={index} ticket={ticket} number={index + 1} ballFrequency={ballFrequency} />
        ))}
      </ul>
    </li>
  );
};

const mapStateToProps = ({
  ticketReducer,
  ballFrequencyReducer,
  isRaffleActive,
  raffleInfoReducer: { raffleTickets },
  pulledDigitsReducer,
  currentBall,
}) => ({
  ticketReducer,
  ballFrequency: ballFrequencyReducer,
  isRaffleActive,
  raffleTickets,
  pulledDigits: pulledDigitsReducer,
  currentBall,
});

export default connect(mapStateToProps)(CurrentGame);
