import { TICKET_TYPES } from '../actionTypes';
import { getStoreState } from '../store';
import axios from 'axios';
import { getToken, url } from '../../constants/api';
import { KENO_TYPES } from '../../constants/game';
import { resetBetButtons } from './betBtnActions';
import { setSelectedAmount } from './amountActions';
import { DATA_TYPES } from '../actionTypes';
import { setError } from './errorActions';
import { mathTime } from '../../constants/game';
import { setIsBetCanceled } from './optionActions';

const { ADD_TICKET, REPLACE_TICKETS } = TICKET_TYPES;
const { UPDATE_USER_DATA } = DATA_TYPES;

function addTicket(ticket) {
  return { type: ADD_TICKET, payload: ticket };
}

function updateUserData(userBalance) {
  return { type: UPDATE_USER_DATA, value: userBalance };
}

export function replaceTickets(tickets) {
  return { type: REPLACE_TICKETS, payload: tickets };
}

export function sendTicket(ticket) {
  const { kenoType, betAmountOptionReducer, frontSecTillDraw } = getStoreState();
  return (dispatch) => {
    return new Promise((resolve) => {
      const {
        selectedAmount: amount,
        userDataReducer: userData,
        drawInfoReducer: { drawId: raceId },
      } = getStoreState();
      axios
        .post(
          `${url}/place-bet`,
          {
            token: getToken(),
            balls: ticket.digits,
            amount: String(amount),
            duration: kenoType,
            raceId,
          },
          {
            timeout: 10 * 1000,
          },
        )
        .then((res) => {
          const { data, error, message } = res.data;
          if (error) {
            dispatch(setIsBetCanceled(true));
            resolve();
          } else {
            dispatch(addTicket(ticket));
            dispatch(resetBetButtons);
            dispatch(updateUserData(data.balance));
            resolve();
          }
        })
        .catch((e) => {
          console.log('sendTicket error: ', e);
          dispatch(setIsBetCanceled(true));
          `${e}` === 'Error: Network Error'
            ? dispatch(setError('Error. Low internet connection.'))
            : dispatch(setError('Something went wrong, please try again.'));
          resolve();
        });
    });
  };
}
