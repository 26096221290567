import {PULLED_DIGIT_TYPES, RAFFLE_TYPES} from '../actionTypes';

const {SET_PULLED_DIGIT, RESET_PULLED_DIGITS} = PULLED_DIGIT_TYPES;
const {SHOW_BALL, SHOW_RAFFLE, HIDE_RAFFLE} = RAFFLE_TYPES;

export function showBall(ball) {
  return {type: SHOW_BALL, payload: ball};
}

export function showRaffle() {
  return {type: SHOW_RAFFLE};
}

export function hideRaffle() {
  return {type: HIDE_RAFFLE};
}

export function setPulledDigit(digit) {
  return {type: SET_PULLED_DIGIT, payload: digit};
}

export function resetPulledDigits() {
  return {type: RESET_PULLED_DIGITS};
}