import { getStoreState } from '../../../keno_desktop/src/redux/store';
import { KENO_TYPES } from '../../../keno_desktop/src/constants/game';

export function formatFrequencyBalls(balls) {
  const copyBalls = JSON.parse(JSON.stringify(balls));
  Object.values(copyBalls).forEach((section) => {
    Object.keys(section).forEach((type) => {
      section[type] = section[type].reduce((acc, el) => {
        acc[el[0]] = el[1];
        return acc;
      }, {});
    });
  });
  return copyBalls;
}

export function getTicketBallColor(ballFrequency, digit) {
  const { activeTab, balls } = ballFrequency;
  return (
    (balls[activeTab]?.hotBalls.hasOwnProperty(digit) && 'red') ||
    (balls[activeTab]?.coldBalls.hasOwnProperty(digit) && 'blue') ||
    ''
  );
}

export function getGameInitialDuration() {
  return getStoreState().kenoType === KENO_TYPES.KENO_1 ? 93 : 183;
}

export function getKenoType() {
  return getStoreState().kenoType;
}

export function generateRandomDigits(digit) {
  const btns = [];
  while (btns.length < digit) {
    const currentDigit = Math.floor(Math.random() * 80) + 1;
    if (!btns.includes(currentDigit)) btns.push(currentDigit);
  }
  return btns;
}
