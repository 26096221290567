import React from 'react';
import { useSelector } from 'react-redux';
import AmountButton from './AmountButton';

const BetAmountButtons = () => {
  const amountRanges = useSelector((state) => state.amountRangesReducer);

  return (
    <ul className="input-controls__list">
      {amountRanges.map((amount) => {
        return <AmountButton key={amount} amount={amount} betAmount={amount} />;
      })}
    </ul>
  );
};

export default BetAmountButtons;
