export const BET_BTN_TYPES = {
  ADD_BTN: 'ADD_BTN',
  ADD_GROUP_BTNS: 'ADD_GROUP_BTNS',
  DELETE_GROUP_BTNS: 'DELETE_GROUP_BTNS',
  DELETE_BTN: 'DELETE_BTN',
  RESET_BTNS: 'RESET_BTNS',
  ADD_RANDOM_BTNS: 'ADD_RANDOM_BTNS',
  REMOVE_LAST: 'REMOVE_LAST',
};

export const DATA_TYPES = {
  SET_ERRORS: 'SET_ERRORS',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_BALL_FREQUENCY: 'SET_BALL_FREQUENCY',
  SET_FREQUENCY_TAB: 'SET_FREQUENCY_TAB',
  SET_USER_BALANCE: 'SET_USER_BALANCE',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  SET_DRAW_HISTORY: 'SET_DRAW_HISTORY',
  SET_USER_HISTORY: 'SET_USER_HISTORY',
  SET_BET_AMOUNT_OPT: 'SET_BET_AMOUNT_OPT',
  SET_SEC_TILL_DRAW: 'SET_SEC_TILL_DRAW',
  SET_DRAW_INFO: 'SET_DRAW_INFO',
  SET_GAME_VERSIONS: 'SET_GAME_VERSIONS',
  SET_FRONT_SEC_TILL_DRAW: 'SET_FRONT_SEC_TILL_DRAW',
  SET_USER_ID: 'SET_USER_ID',
  SET_GAME_LEADERBOARD: 'SET_GAME_LEADERBOARD',
  UPDATE_GAME_LEADERBOARD: 'UPDATE_GAME_LEADERBOARD',
  SET_ACTIVE_LANGUAGE: 'SET_ACTIVE_LANGUAGE',
  TOGGLE_SOUND: 'TOGGLE_SOUND',
  SET_AMOUNT_RANGES: 'SET_AMOUNT_RANGES',
  SET_HOME_BUTTON: 'SET_HOME_BUTTON',
};

export const TICKET_TYPES = {
  ADD_TICKET: 'ADD_TICKET',
  REMOVE_TICKET: 'REMOVE_TICKET',
  REPLACE_TICKETS: 'REPLACE_TICKETS',
};

export const TAB_TYPES = {
  SELECT_TAB: 'SELECT_TAB',
};

export const AMOUNT_TYPES = {
  SET_SELECTED_AMOUNT: 'SET_SELECTED_AMOUNT',
  PLUS_AMOUNT: 'PLUS_AMOUNT',
};

export const PAYOUT_TYPES = {
  ADD_PAYOUT: 'ADD_PAYOUT',
};

export const DURATION_TYPES = {
  SET_DURATION: 'SET_DURATION',
};

export const CHANGE_SCENE = 'CHANGE_SCENE';

export const SET_CONGRATS = 'SET_CONGRATS';

export const SHOW_POPUP = 'SHOW_POPUP';
export const CLOSE_ERRORS_POPUP = 'CLOSE_ERRORS_POPUP';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_GAME_TYPE = 'SET_GAME_TYPE';
export const SET_IS_BET_CANCELED = 'SET_IS_BET_CANCELED';

export const POPUP_TYPES = {
  LEADERBOARD_SHOW_POPUP: 'LEADERBOARD_SHOW_POPUP',
  LEADERBOARD_HIDE_POPUP: 'LEADERBOARD_HIDE_POPUP',
};

export const RAFFLE_TYPES = {
  SHOW_BALL: 'SHOW_BALL',
  SHOW_RAFFLE: 'SHOW_RAFFLE',
  HIDE_RAFFLE: 'HIDE_RAFFLE',
};

export const ADD_RAFFLE_INFO = 'ADD_RAFFLE_INFO';

export const PULLED_DIGIT_TYPES = {
  SET_PULLED_DIGIT: 'SET_PULLED_DIGIT',
  RESET_PULLED_DIGITS: 'RESET_PULLED_DIGITS',
};

export const IS_FETCHING_TYPES = {
  SET_IS_FETCHING: 'SET_IS_FETCHING',
  RESET_IS_FETCHING: 'RESET_IS_FETCHING',
};

export const COUNT_FOR_HISTORY = 'COUNT_FOR_HISTORY';
