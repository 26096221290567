import React, { useState } from 'react';
import images from '../../assets/images';
import { changePopup } from '../../redux/actions/popupActions';
import { connect, useSelector } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';

const { step_1, step_2, step_3, step_4, step_5, step_6, step_7 } = images;

const gameInfoText_1 = 'gameInfoText_1';
const gameInfoText_2 = 'gameInfoText_2';
const gameInfoText_3 = 'gameInfoText_3';
const gameInfoText_4 = 'gameInfoText_4';
const gameInfoText_5 = 'gameInfoText_5';
const gameInfoText_6 = 'gameInfoText_6';
const gameInfoText_7 = 'gameInfoText_7';
const gameInfoText_8 = 'gameInfoText_8';
const playText = 'Play';
const stepsText = 'stepsText';

const gameInfo = {
  [gameInfoText_1]: step_1,
  [gameInfoText_2]: step_2,
  [gameInfoText_3]: step_3,
  [gameInfoText_4]: step_4,
  [gameInfoText_5]: step_5,
  [gameInfoText_6]: step_6,
  [gameInfoText_7]: step_7,
  [gameInfoText_8]: playText,
};

const Slides = ({ changePopup }) => {
  const maxLength = Object.keys(gameInfo).length;

  const language = useSelector((state) => state.activeLanguageReducer);

  const languageData = useLanguageData();

  const [currentSlide, setCurrentSlide] = useState(0);

  const slideIndex = (num) => {
    let index;
    if (currentSlide + num >= maxLength) {
      index = 0;
    } else if (currentSlide + num < 0) {
      index = maxLength - 1;
    } else {
      index = currentSlide + num;
    }
    setCurrentSlide(index);
  };

  return (
    <>
      <div className="slideShow-container">
        <div className="popup__title">{languageData[stepsText]}</div>
        {Object.entries(gameInfo).map(([info, img], index, array) => {
          return (
            <div
              key={info}
              style={{ display: index === currentSlide ? 'block' : 'none', textAlign: 'center' }}>
              <div className="text">{languageData[info]}</div>
              {img === 'Play' ? (
                <span
                  className={`welcome__play-game slide ${
                    language === 'sw' ? 'playButtonSw' : 'playButtonEn'
                  }`}
                  style={{ height: '300px' }}
                  onClick={changePopup}></span>
              ) : (
                <img className="slides" src={img} />
              )}

              <div className="numberText">{`${index + 1} / ${array.length}`}</div>
            </div>
          );
        })}
        <a className="prev" onClick={() => slideIndex(-1)}>
          &#10094;
        </a>
        <a className="next" onClick={() => slideIndex(1)}>
          &#10095;
        </a>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  changePopup,
};
export default connect(null, mapDispatchToProps)(Slides);
